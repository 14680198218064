<template>
  <div class="Home">
    <div class="Tabs" v-show="profileDataMenu && profileDataMenu.length > 0">
      <!-- <div class="TabsContent">
        <button class="Tab Selected" @click="() => onTab(false)" ref="withc">
          Con Cita {{ appointmentsLenght ? `(${appointmentsLenght})` : "" }}
        </button>
        <button class="Tab" @click="() => onTab(true)" ref="without">
          Sin Cita {{ turnsLength ? `(${turnsLength})` : "" }}
        </button>
      </div> -->
      <p class="statusBadge" v-if="loggedUser && loggedUser.worker">
        Puesto de trabajo: {{ loggedUser.worker.name }}
      </p>
    </div>

    <div class="Table" v-show="profileDataMenu && profileDataMenu.length > 0">
      <v-dialog v-model="showAlert" max-width="400">
        <div style="background: #fffd; padding: 1rem">
          <h4>
            {{
              errorCalling
                ? errorCalling
                : "Debe finalizar primero el turno que está atendiendo para cambiar de puesto de trabajo"
            }}
          </h4>
          <div style="display: flex; justify-content: center">
            <v-btn @click="closeAlertCalling">OK</v-btn>
          </div>
        </div>
      </v-dialog>

      <div v-if="errorService" class="text-black">
        <h2 class="text-center pt-5 m-0" style="color: black">
          Ahora selecciona por favor un servicio
        </h2>
      </div>

      <div v-else-if="errorForce">
        <h2 class="text-center">
          {{ errorForce }}
        </h2>
        <div class="buttons d-flex justify-center">
          <v-btn
            v-if="errorForce && errorForce.includes('ya se encuentra tomado')"
            @click="$router.push({ path: '/home/' })"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="errorForce && errorForce.includes('ya se encuentra tomado')"
            color="error"
            class="ml-3"
            @click="() => startDoctor(true)"
            >Forzar</v-btn
          >
        </div>
      </div>

      <header v-else class="Table__Header">
        <div
          class="Table__Header--container"
          v-for="(header__item, index) in header"
          :key="index"
        >
          <p
            class="Header__text"
            :class="header__item.lastname && 'Header__text__centered'"
          >
            {{ header__item.name }}
            <span class="d-block">{{ header__item.lastname }}</span>
          </p>
        </div>
      </header>

      <div class="TableContent">
        <v-progress-linear
          :active="loadingMainTurns"
          :indeterminate="true"
          bottom
          color="primary"
        />

        <TableBodyTurns
          v-show="!errorForce && !errorService"
          @finished="fetchTurns(fromDate, toDate)"
        />

        <div class="divisor">
          <div class="line"></div>
        </div>

        <div style="padding-bottom: 245px">
          <TableBody
            v-show="!errorForce && !errorService"
            @finished="fetchTurns(fromDate, toDate)"
          />
        </div>

        <v-snackbar v-model="snackbar">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";
// var AWS = require("aws-sdk");

export default {
  name: "Home",

  data() {
    return {
      timer: null,
      dataHours: 24,
      currentTimer: null,
      currentTime: "",
      turnIntervalId: null,
      appointmentsIntervalId: null,
      showAlert: false,
      errorService: false,
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      showAppointment: true,

      // snackBar
      text: "",
      snackbar: false,
      // ---
    };
  },

  components: {
    TableBody: () => import("../components/TableBody.vue"),
    TableBodyTurns: () => import("../components/TableBodyTurns.vue"),
  },

  async mounted() {
    // restart all timers
    this.restartTimers();

    // this.$root.enableChangeWorkStation = true;
    if (!this.getThemeMode.verticalSidebarDrawer) {
      this.changeVerticalSidebarDrawer(true);
    }

    // Check Service Selected
    let service = this.$route.query?.service;

    if (!service) {
      this.changeThemeLoadingState(false);
      return (this.errorService = true);
    } else {
      this.errorService = false;
    }

    setTimeout(() => {
      this.getCupsCode({
        speciality: this.$route.query?.service,
        VUE_APP_FSFB_SERVER_KIOSKOS_DISTRIBUTION:
          this.environmentData.env?.VUE_APP_FSFB_SERVER_KIOSKOS_DISTRIBUTION,
        VUE_APP_API_KEY_DEV: this.environmentData.env?.VUE_APP_API_KEY_DEV,
      });
    }, 2000);

    this.startDoctor();

    // // check local lambdfa function
    // var lambda = new AWS.Lambda();
    // console.log(lambda)
    // lambda.addLayerVersionPermission(params, function (err, data) {
    //   if (err) console.log(err, err.stack); // an error occurred
    //   else     console.log(data);           // successful response
    // });
  },

  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.currentTimer);
  },

  watch: {
    selectedBranch(val) {
      if (val) {
        if (!this.getThemeMode.verticalSidebarDrawer) {
          this.changeVerticalSidebarDrawer(true);
        }
      }
    },
    async serviceDoctor(val) {
      // check service
      if (!this.services) return;

      let ser = this.services.find((s) => s.code == val);
      if (!ser) return;

      this.resetErrorCalling();

      // if (Object.keys(this.loggedUser).length > 0 && this.loggedUser.worker) {
      //   this.workerPlaceWaitingRoom(ser.debMedia);
      // }

      // init without service
      if (this.errorService) {
        this.errorService = false;
        this.startDoctor();
      }

      await this.getCupsCode({
        speciality: this.$route.query?.service,
        VUE_APP_FSFB_SERVER_KIOSKOS_DISTRIBUTION:
          this.environmentData.env?.VUE_APP_FSFB_SERVER_KIOSKOS_DISTRIBUTION,
        VUE_APP_API_KEY_DEV: this.environmentData.env?.VUE_APP_API_KEY_DEV,
      });
    },
    '$route.query.service': {
      handler: function(service) {
        this.errorService = service ? false:true; 
      },
      deep: true,
      immediate: true
    },
    loggedUser(data) {
      this.setLoggedUserPanel(data);
      setTimeout(() => {
        this.setLoadingMainTurns(false);
      }, 3000);
    },
    errorCalling(e) {
      if (e) this.showAlert = true;
    },
    "$route.params.workStationId"(workStationId) {
      this.resetErrorCalling();
      this.loggedUserGetAction({
        branchId: this.selectedBranch.id,
        workerId: parseFloat(workStationId),
        force: false,
      });
    },
    errorStaffAppointment(e) {
      if (e) {
        this.text = e;
        this.snackbar = true;
      } else this.snackbar = false;
    },
  },

  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapState({
      selectedBranch: (state) => state.branchesSilver.selectedBranch,
      branchesData: (state) => state.branchesSilver.branchesData,
      header: (state) => state.doctorPanel.header,
      headerTurns: (state) => state.doctorPanel.headerTurns,
      body: (state) => state.doctorPanel.body,
      environmentData: (state) => state.environmentSilver.environment,
      loggedUser: (state) => state.loggedUserSilver.loggedUserData,
      appointments: (state) => state.doctorPanel.appointments,
      turns: (state) => state.doctorPanel.turns,
      loadingTurns: (state) => state.doctorPanel.loadingTurns,
      loadingMainTurns: (state) => state.doctorPanel.loadingMainTurns,
      loadingDoctorPanelAppointments: (state) =>
        state.doctorPanel.loadingDoctorPanelAppointments,
      errorForce: (state) => state.loggedUserSilver.errorForce,
      serviceDoctor: (state) => state.doctorPanel.serviceDoctor,
      services: (state) => state.doctorPanel.services,
      cupsCodes: (state) => state.doctorPanel.cupsCodes,
      errorCalling: (state) => state.loggedUserSilver.errorCalling,
      errorStaffAppointment: (state) => state.doctorPanel.errorStaffAppointment,
      pollingData: (state) => state.pollingWorkplaceSilver.polling,
      profileData: (state) => state.profileSilver.profileData,
      silver: (state) => state.environmentSilver.environment.silver,
    }),

    appointmentsLenght() {
      let appointments = [];
      if (this.cupsCodes.length > 0) {
        appointments = this.appointments.filter((a) =>
          this.cupsCodes.includes(a.attention.code)
        );
      }
      return appointments.length;
    },

    turnsLength() {
      return this.turns.length;
    },

    // headerComputed() {
    //   // return !this.showAppointment && !this.errorForce && !this.errorService
    //   //   ? this.headerTurns
    //   //   : this.header;
    // },

    profileDataMenu() {
      let service = this.$route.query?.service;
      try {
        if(service){
          let prefix = this.services.find((s) => s.code?.toLowerCase() == service?.toLowerCase())?.prefix;

          return this.profileData?.menu
            ?.filter((menuItem) => menuItem.attribute.url.includes("workstation"))
            .map((m) => m.attribute)
            .filter((i) => i.label.toLowerCase().includes(prefix.toLowerCase()));
        } else return [];
      } catch (error) {
        console.log("error")
        console.log(error)

        return this.profileData?.menu
          ?.filter((menuItem) => menuItem.attribute.url.includes("workstation"))
          .map((m) => m.attribute)
          .filter((i) => i.label.includes(service));
      }    
    },
  },

  methods: {
    ...mapActions([
      "getCompanyBranchesInfo",
      "changeVerticalSidebarDrawer",
      "loggedUserGetAction",
      "fetchAppointmentsStaff",
      "getMonitorTurns",
      "changeThemeLoadingState",
      "workerPlaceWaitingRoom",
      "getCupsCode",
      "pollingWorkplaceGetAction",
      "setLoggedUserPanel",
      "resetErrorCalling",
      "updateStateAction",
      "setLoadingMainTurns",
    ]),

    onTab(without) {
      if (!without) {
        this.$refs.withc.classList.add("Selected");
        this.$refs.without.classList.remove("Selected");
        this.showAppointment = true;
      } else {
        this.$refs.without.classList.add("Selected");
        this.$refs.withc.classList.remove("Selected");
        this.showAppointment = false;
      }
    },

    restartTimers() {
      // restart all intervals
      const interval_id = window.setInterval(function () {},
      Number.MAX_SAFE_INTEGER);

      // Clear any timeout/interval up to that id
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }
    },

    async pollingTunrns(fromDate, toDate, interval) {
      this.changeThemeLoadingState(true);
      this.fetchTurns(fromDate, toDate);
      this.fetchAppointmentsFSFB(fromDate, toDate);

      // Fetch debmedia turns
      this.turnIntervalId = setInterval(async () => {
        this.fetchTurns(fromDate, toDate);
      }, interval);

      // Fetch Appointments
      this.appointmentsIntervalId = setInterval(async () => {
        this.fetchAppointmentsFSFB(fromDate, toDate);
      }, 15000);
    },

    async fetchTurns(fromDate, toDate) {
      // fetch debmedia turns and join with appointments
      // await this.getMonitorTurns( {fromDate: "2022-07-17", toDate: "2022-07-17", branch_id: this.selectedBranch.id});
      await this.getMonitorTurns({
        fromDate,
        toDate,
        branch_id: this.selectedBranch.id,
        silver: this.silver,
      });

      this.pollingWorkplaceGetAction();
    },

    async fetchAppointmentsFSFB(fromDate, toDate) {
      if (this.loadingDoctorPanelAppointments) return;

      // fetch email from login
      let user_email = localStorage.getItem("user_email");

      // fetch doctor appointments
      await this.fetchAppointmentsStaff({
        VUE_APP_FSFB_SERVER_STAFF_APPOINTMENTS:
          this.environmentData.env?.VUE_APP_FSFB_SERVER_STAFF_APPOINTMENTS,
        VUE_APP_API_KEY_DEV: this.environmentData.env?.VUE_APP_API_KEY_DEV,
        doc: user_email.split("@")[0],
        fromDate,
        toDate,
      });
    },

    startDoctor(force = false) {
      // let fromDate = "2022-07-09";
      // let toDate = "2022-07-09";
      // let fromDate = moment().format("YYYY-MM-DD");
      // let toDate = fromDate;

      this.changeThemeLoadingState(true);

      /* eslint-disable */
      setTimeout(async () => {

        // loggin on workstation
        await this.loggedUserGetAction({
          branchId: this.selectedBranch.id,
          workerId: parseFloat(this.$route.params.workStationId),
          force: force,
        });


        // Log in queue
        setTimeout(async () => {
          // let service = this.$route.query?.service;
          // let ser = this.services.find((s) => s.code == service);
          // await this.workerPlaceWaitingRoom(ser.debMedia);

          if(this.errorForce || !this.loggedUser) return;

          const filteredResponse = this.handlerStateAgentToSelect(
            this.loggedUser
          )?.find((valResponse) => valResponse.name === "Conectado - Disponible");

          if(filteredResponse){
            await this.updateStateAction({
              id: filteredResponse.id,
              name: filteredResponse.name,
            }).then(() => {
              this.selectOptionState = filteredResponse;
            });
          }

          // poll of debmedia turns
          this.pollingTunrns(this.fromDate, this.toDate, 10000);

        }, 2000);

      }, 1000);
      /* eslint-enable */
    },

    closeAlertCalling() {
      this.resetErrorCalling();
      this.showAlert = false;
    },

    handlerStateAgentToSelect(val) {
      if (val.worker) {
        return val.worker?.workerProfile?.possibleStates?.map((state) => ({
          name: state.name,
          value: state.name,
          id: state.id,
        }));
      } else {
        return val.workerProfile?.possibleStates?.map((state) => ({
          name: state.name,
          value: state.name,
          id: state.id,
        }));
      }
    },
  },
};
</script>

<style scoped>
.Home {
  padding: 1rem;
}

.Home_Alert {
  font-size: 1.5rem;
  font-weight: 600;
}

.Table {
  overflow-y: auto;
  scroll-behavior: smooth;
  max-height: calc(100vh - 139.8px);
  position: relative;
  display: grid;
  /* grid-template-columns: repeat(9, minmax(100px, 1fr));
  grid-template-rows: minmax(71px, 1fr);
  grid-auto-rows: minmax(130px, 1fr); */
  grid-template-columns: 1fr;
  /* grid-template-rows: 71px minmax(65px, 1fr); */
  width: 100%;
  /* height: calc(100vh - 159px); */
  overflow-x: auto;
  border-radius: 4px;
  background-color: white;
  color: var(--color-panel-primary);
}

.TableContent {
  padding-left: 83px;
  padding-right: 42px;
}

.Table__Header,
.Table__Header--Turns {
  /* position: sticky; */
  grid-row: 1 / 2;
  display: grid;
  align-items: center;
  justify-items: start;
  padding-bottom: 13px;
  border-bottom: 1px solid rgba(44, 66, 108, 0.6);
  grid-template-columns:
    repeat(2, var(--grid-minmax))
    minmax(250px, 350px) repeat(5, var(--grid-minmax)); /* TODO: 2. Change me to 8 and enable SMS column */
  grid-template-rows: minmax(71px, 1fr);
  grid-auto-rows: minmax(130px, 1fr);
}

.Table__Header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 900007;
  padding-left: 83px;
  padding-right: 42px;
}

.Table__Header--Turns {
  grid-template-columns: var(--grid-minmax) minmax(250px, 350px) repeat(
      5,
      var(--grid-minmax)
    );
}

.movementLabel {
  position: absolute;
  width: fit-content;
  height: 23px;
  left: 21px;
  top: 62px;
  z-index: 5;
}

.movementLabel__text {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  color: white;
}

.Header__text {
  margin: 0;
  line-height: 21px;
  font-size: 17px;
  font-weight: bold;
  color: var(--color-panel-primary);
}

.Header__text__centered {
  text-align: start;
  padding: 0;
}

.Tab {
  padding: 0.5rem 5rem;
  margin-right: 0.5rem;
  margin-bottom: -3px;
  border-radius: 5px 5px 0 0;
  background-color: rgb(235, 235, 235);
  transition: 0.5s all;
  font-weight: 500;
  font-size: 20px;
}

.Tab.Selected {
  background-color: white;
}

.statusBadge {
  margin: 0;
}

.Tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TabsContent {
  display: flex;
  overflow-x: auto;
  max-width: 520px;
  overflow-y: hidden;
}

.progress {
  position: absolute;
  top: 17%;
  width: 89%;
  left: 7%;
}

.divisor {
  width: 100%;
  height: 65px;
  text-align: center;
  padding: 0;
  font-weight: bold;
  position: relative;
}

.line {
  height: 5px;
  background: #3a3a3a;
  position: absolute;
  left: -83px;
  top: 50%;
  transform: translateY(-50%);
  right: -42px;
}
</style>
